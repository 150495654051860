<template>
  <div v-if="questionTypes">
    <base-table
        v-if="shownData.length"
        :columns="questionTypeFields"
        :rows="shownData"
    />

    <table-pagination
        v-if="shownData.length"
        :totalPages="1"
        :countOnPage="countOnPage"
        :total="rowData.length"
        :count="countOnPage > shownData.length ? shownData.length : countOnPage"
        @changePage="changePage"
    />
    <div v-else>
      <p>Нет результатов</p>
    </div>
  </div>
  <div v-else class="table-preloader">
    <preloader />
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useStore } from "vuex";
import { computed, onBeforeMount } from "@vue/runtime-core";
import Preloader from "@/components/Technical/Preloader.vue";
import TablePagination from "@/components/OtherElements/TablePagination.vue";
import BaseTable from "@/components/Tables/BaseTable.vue";
export default {
  name: "qtype-table",
  components: {
    BaseTable,
    TablePagination,
    Preloader,
  },
  setup() {
    const store = useStore();

    const countOnPage = ref(15),
      isPreloader = ref(false);
    const questionTypes = computed(() => store.state.test.questionTypes),
      rowData = computed(() => {
        return questionTypes.value.map((type) => {
          return {
            id: type.id,
            name: type.name,
            color: type.color,
            edit: type.id,
          };
        });
      });

    const pageDataStart = ref(0);
    const shownData = computed(() => {
      return rowData.value ? rowData.value.slice(pageDataStart.value, pageDataStart.value + countOnPage.value) : [];
    });

    const changePage = (page) => {
      pageDataStart.value = (page - 1) * countOnPage.value;
    };

    const questionTypeFields = [
      {
        field: "name",
        headerName: "Название",
      },
      {
        field: "color",
        headerName: "Цвет",
        cellRenderer: "ColorRenderer",
      },
      {
        field: "edit",
        headerName: "",
        dest: "test/question-type",
        res: "edit",
        idRequired: true,
      },
    ];

    onBeforeMount(() => {
      store.dispatch("test/getQuestionTypes");
    });

    return {
      countOnPage,
      isPreloader,
      questionTypes,
      rowData,
      shownData,
      changePage,
      questionTypeFields,
    };
  },
};
</script>

<style lang="scss" scoped>
.qtype-table {
  &__color {
    height: 13px;
    width: 13px;
    display: inline-block;
    margin-right: 4px;
    border: 1px solid var(--cream-color);
  }
}
</style>
